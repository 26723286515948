
.modal-content::-webkit-scrollbar {
    width: 6px;
  }
  .modal-content::-webkit-scrollbar-thumb {
    background-color: #5e9270ab;
      border-radius: 10px;
  }
  .modal-content::-webkit-scrollbar-track {
    background-color: #f1f1f1; }
  .modal-content p {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    word-spacing: 0.2em;
    border-left: 1px solid white;
    width: 530px;
    color: #535353;
    margin-top:10px;
    font-size: large;
  }
  .modal-content {
    position: relative;
    max-height: 89vh; 
    overflow-y: auto; 
  }
  .modalHead {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 1rem 0;  
  }
  .modal-enter {
    opacity: 0;
  }
  
  .modal-enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }
  
  .modal-exit {
    opacity: 1;
  }
  
  .modal-exit-active {
    opacity: 0;
    transition: opacity 100ms;
  }
  
  .cartDiv{
    background: rgba(255, 255, 255, 0.26);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    width:300px;
    }
  @media screen and (max-width: 1280px) {

    .modal-content p {
      width: 90%;
      margin-top:10px;
      font-size:medium;
    }
    .modal-content button{
      width:70px;
      padding:3px;
      font-size: medium;
      height:35px;
    } 
  }