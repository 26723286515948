@font-face {
  font-family:'Vazir';
  src: url('./assets/Fonts/BYekan+.ttf');
}
body {
  font-family: 'Vazir', sans-serif;
}
li:hover{
  cursor: pointer;
}
.dropdown-menu {
  display: none;
  position: absolute;
  color: #717171;
  padding-left: 10px;
  text-align: right;
  margin-left:30px;
  z-index: 10;
  animation: fadeIn 0.3s ease forwards;
  font-weight: 100;
  
}
.home{
  background: url(./assets/TSTSPORT.IR.jpg);
  height: 90vh;
  object-fit:contain;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.layout{
  background-color: #fff;
}
.category {
  position: relative;
  height: 90vh;
  overflow: hidden;
}
.hover-show {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.parent:hover .hover-show {
  opacity: 1;
  visibility: visible;
}

.category::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/TSTSPORT.IR.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
.dropdown:hover .dropdown-menu {
  display: block;
  animation: slideDown 0.3s ease forwards;
}
@keyframes slideDown {
  from {
      opacity: 0;
      transform: translateY(-10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
.sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media screen and (max-width: 1280px) {
    .sections {
      grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (max-width: 830px) {
  .sections {
    grid-template-columns:1fr 1fr;
  }
}
@media screen and (max-width: 480px) {
  .home{
    .category {
      position: relative;
      height: auto;
      overflow: hidden;
    }
    background: url(./assets/phone_cleanup.png);
    object-fit:fill;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .sections {
    grid-template-columns: 1fr;
  }
  .Navbar{
    display: none;
  }
  .Blogo{
    display: none;
  }
  .sideBar{
    display: none;
  }
  
}
@media screen and (min-width: 480px) {
.mobileMenu{
  display: none;
}
.sideBarr{
  display: none;
}
}
